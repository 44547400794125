<template>
  <div class="grid gap-12">
    <VTitle :title="$t('app.reports_learners', 2)" class="section-title" />

    <Filters @search="onClickSearch" @export="onClickExport" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <DataView v-else :data="data">
      <Report
        v-for="item in data?.learners"
        :key="item?.id"
        :report="item"
        class="border-t border-dashed border-theme-15 py-12"
        :class="{ 'intro-y': hasAnimation }"
      />
    </DataView>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useDownload from "@/composables/useDownload";
import useAnimation from "@/composables/useAnimation";
// Components
import Filters from "./Filters";
import Report from "./Report";
import DataView from "../DataView";
import VTitle from "@/components/VTitle";

export default {
  components: {
    Filters,
    Report,
    DataView,
    VTitle
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { download } = useDownload();
    const { hasAnimation } = useAnimation();

    // Data
    const data = ref([]);
    const isLoading = ref(false);

    // Constants
    const ENDPOINT = "teaching.reports.learners";
    // eslint-disable-next-line
    const documentTitle = `${t("app.learners", 2)} - ${t("app.reports", 2)} - ${t("app.teaching")}`;

    // Methods
    const onClickExport = values => {
      download(ENDPOINT, {}, values);
    };

    const getReports = async data => {
      return await request({
        endpoint: ENDPOINT,
        data
      });
    };

    const onClickSearch = async values => {
      isLoading.value = true;
      const response = await getReports(values);

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    return {
      isLoading,
      data,
      documentTitle,
      onClickSearch,
      onClickExport,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
