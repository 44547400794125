<template>
  <BaseReport
    :report="report"
    :attributes="ATTRIBUTES"
    report-class="bg-theme-5"
  >
    <template #image>
      <VUserAvatar :user="report" class="mr-3" />
    </template>

    <template #name>
      {{ name }}
    </template>

    <template #subtitles>@{{ username }}</template>

    <template #registrations>
      <Registrations
        v-if="report?.registrations?.length"
        :registrations="report?.registrations"
      />
    </template>
  </BaseReport>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Components
import BaseReport from "../BaseReport";
import VUserAvatar from "@/components/VUserAvatar";
import Registrations from "./Registrations";

export default {
  components: {
    BaseReport,
    Registrations,
    VUserAvatar
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    const ATTRIBUTES = {
      credits_completed: t("app.credits_completed", 2),
      hours_completed: t("app.hours_completed", 2)
    };

    // Computed
    const learner = computed(() => props.report ?? {});
    // eslint-disable-next-line
    const name = computed(() => `${learner.value?.firstname} ${learner.value?.lastname}`);
    const username = computed(() => learner.value?.username);

    return {
      name,
      username,
      ATTRIBUTES
    };
  }
};
</script>
