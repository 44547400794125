<template>
  <VList :rows="rows" :headers="HEADERS" hide-header>
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.name" :color="item?.colour" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.registration="{ item }">
      <div class="font-medium">
        {{ item.name }}
      </div>

      <div class="version">
        {{ $t("app.versions") }}
        {{ item?.version }}
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.started="{ item }">
      <div class="cursor-pointer">
        <div class="whitespace-nowrap">
          {{ $t("app.registered_on") }}

          <span class="font-medium">
            {{ item.registered }}
          </span>
        </div>

        <div class="whitespace-nowrap mt-0.5">
          {{ $t("app.started_on") }}

          <span class="font-medium">
            {{ item.started }}
          </span>
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.completed="{ item }">
      <div class="cursor-pointer">
        <div class="whitespace-nowrap">
          {{ $t("app.completed_on") }}

          <span class="font-medium">
            {{ item.completed }}
          </span>
        </div>

        <div class="whitespace-nowrap mt-0.5">
          {{ $t("app.approved_on") }}

          <span class="font-medium">
            {{ item.approved }}
          </span>
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item?.registration?.status"
          :class="getStatusColor(item?.registration?.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VList,
    VImage,
    VChip
  },
  props: {
    registrations: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();

    // Constants
    const HEADERS = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.registrations"),
        value: "registration",
        headerClass: "w-1/4",
        cellClass: "w-1/4"
      },
      {
        value: "started",
        headerClass: "w-1/4",
        cellClass: "w-1/4"
      },
      {
        value: "completed",
        headerClass: "w-1/4",
        cellClass: "w-1/4"
      },
      {
        text: t("app.status"),
        value: "status"
      }
    ];

    // Computed
    const userLang = computed(() => store.getters["user/language"]);

    const rows = computed(() => {
      return props.registrations.map(x => {
        const { training, registration } = x ?? {};
        const {
          registered_on,
          started_on,
          learner_completed_on,
          completion_approved_on
        } = registration ?? {};

        const name = training?.name?.[userLang.value]?.name;

        return {
          ...x,
          name,
          colour: training?.colour,
          version: training?.version,
          started: formatDate({ date: started_on }),
          registered: formatDate({ date: registered_on }),
          completed: formatDate({ date: learner_completed_on }),
          approved: formatDate({ date: completion_approved_on })
        };
      });
    });

    return {
      HEADERS,
      rows,
      // useColor
      getStatusColor
    };
  }
};
</script>
