<!-- eslint-disable -->
<template>
  <div class="flex">
    <div v-if="src" :class="`w-${width} h-${height}`">
      <img class="rounded-full" :src="src" />
    </div>

    <div
      v-else
      :class="`w-${width} h-${height}`"
      class="relative inline-block align-middle rounded-full border"
    >
      <div
        v-if="initials"
        class="left-1/2 top-1/2 absolute transform -translate-x-1/2 -translate-y-1/2"
      >
        {{ initials }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 10
    }
  },
  setup(props) {
    const { getAvatarURL } = useDisplay();

    // Computed
    const src = computed(() => getAvatarURL(props.user?.avatar?.view_path));
    // eslint-disable-next-line
    const name = computed(() => `${props.user?.firstname} ${props.user?.lastname}`);

    const initials = computed(() => {
      return name.value
        .split(" ")
        .slice(0, 2)
        .map(x => x.charAt(0))
        .join("")
        .toUpperCase();
    });

    return {
      src,
      initials
    };
  }
};
</script>
