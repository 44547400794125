<template>
  <Filters @export="onClickExport" @search="onClickSearch">
    <div class="grid grid-cols-2 gap-2">
      <VSelect
        v-model="searchValues.user_id"
        :label="$t('app.users', 2)"
        multiple
        :options="userOptions"
      />

      <VSelect
        v-model="searchValues.user_status"
        :label="$t('app.user_status')"
        :options="userStatusOptions"
      />
    </div>

    <div class="grid grid-cols-2 gap-2">
      <VSelect
        v-model="searchValues.user_type"
        :label="$t('app.user_type')"
        :options="userTypeOptions"
      />

      <VSelect
        v-model="searchValues.department_id"
        :label="$t('app.departments', 2)"
        multiple
        :options="departmentOptions"
      />
    </div>

    <div class="grid grid-cols-2 gap-2">
      <VSelect
        v-model="searchValues.role_id"
        :label="$t('app.roles', 2)"
        multiple
        :options="roleOptions"
      />

      <VSelect
        v-model="searchValues.training_status"
        :label="$t('app.training_status')"
        :options="trainingStatusOptions"
      />
    </div>

    <div>
      {{ $t("app.registration_period") }}

      <div class="grid grid-cols-2 gap-2 mt-2">
        <VDatePicker v-model="searchValues.registration_period_start" />

        <VDatePicker v-model="searchValues.registration_period_end" />
      </div>
    </div>

    <div>
      {{ $t("app.completion_period") }}

      <div class="grid grid-cols-2 gap-2 mt-2">
        <VDatePicker v-model="searchValues.completion_period_start" />

        <VDatePicker v-model="searchValues.completion_period_end" />
      </div>
    </div>

    <VSelect
      v-model="searchValues.report_view"
      :label="$t('app.report_view')"
      :options="reportViewOptions"
    />
  </Filters>
</template>

<script>
import { ref, onMounted, computed } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
// Components
import Filters from "@/components/templates/teaching/reports/Filters";
import VSelect from "@/components/inputs/VSelect";
import VDatePicker from "@/components/inputs/VDatePicker";

export default {
  components: {
    Filters,
    VSelect,
    VDatePicker
  },
  emits: ["search", "export"],
  setup(props, context) {
    // Composables
    const { ALL_OPTIONS, getUsers, getDepartments } = useOptions();

    // Data
    const userOptions = ref([]);
    const roleOptions = ref([]);
    const departmentOptions = ref([]);

    const searchValues = ref({
      user_id: [],
      user_status: ALL_OPTIONS.ACTIVE.value,
      user_type: ALL_OPTIONS.INTERNAL.value,
      department_id: [],
      role_id: [],
      training_status: ALL_OPTIONS.ACTIVE.value,
      registration_period_start: "",
      registration_period_end: "",
      completion_period_start: "",
      completion_period_end: "",
      report_view: ALL_OPTIONS.SUMMARY.value
    });

    // Computed
    const userStatusOptions = computed(() => [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.INACTIVE,
      ALL_OPTIONS.DISABLED
    ]);

    const userTypeOptions = computed(() => [
      ALL_OPTIONS.INTERNAL,
      ALL_OPTIONS.EXTERNAL
    ]);

    const trainingStatusOptions = computed(() => [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.UNDER_REVIEW,
      ALL_OPTIONS.ARCHIVED
    ]);

    const reportViewOptions = computed(() => [
      ALL_OPTIONS.SUMMARY,
      ALL_OPTIONS.DETAILED
    ]);

    // Lifecycle Hooks
    onMounted(async () => {
      const [users, departments] = await Promise.all([
        getUsers(),
        // getRoles(),
        getDepartments()
      ]);

      userOptions.value = users;
      // roleOptions.value = roles;
      departmentOptions.value = departments;
    });

    // Methods
    const onClickExport = () => {
      context.emit("export", searchValues.value);
    };

    const onClickSearch = () => {
      context.emit("search", {
        ...searchValues.value,
        user_id: searchValues.value?.user_id?.toString(),
        department_id: searchValues.value?.department_id?.toString(),
        role_id: searchValues.value?.role_id?.toString()
      });
    };

    return {
      userOptions,
      userStatusOptions,
      trainingStatusOptions,
      userTypeOptions,
      reportViewOptions,
      departmentOptions,
      roleOptions,
      searchValues,
      onClickExport,
      onClickSearch
    };
  }
};
</script>
